import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { DataProvider, componentMap } from "../contentful/data-provider";
import { Helmet } from "react-helmet";
import ogimage from "../images/ogimages/roadmap.png";

export default function Roadmap({ data }) {
	const dataProvider = new DataProvider(data);
	const [isAllMonthsShow, setIsAllMonthsShow] = useState(false);
	const [isButtonShow, setIsButtonShow] = useState(true);

	function handleClick() {
		setIsAllMonthsShow(true);
		setIsButtonShow(false);
	}

	return (
		<Layout>
			<Helmet
				meta={[
					{
						property: `og:title`,
						content: 'Agoric - Roadmap',
					},
					{
						property: `twitter:title`,
						content: 'Agoric - Roadmap',
					},
					{
						property: `og:type`,
						content: `website`,
					},
					{
						property: `twitter:image`,
						content: ogimage,
					},
					{
						property: `og:image`,
						content: ogimage,
					},
					{
						property: `twitter:card`,
						content: `summary_large_image`,
					},
				]}
			>
				<title>Agoric - Roadmap</title>
			</Helmet>
			<div className={"roadmap-page"}>
				{dataProvider.getCurrentPageSections().map((section, index) => {
					return <React.Fragment key={index}>{React.createElement(componentMap[section.type], { content: dataProvider.getElementById(section.id), isAllMonthsShow })}</React.Fragment>;
				})}

				<div className='container'>
					<button className='btn mt-4 mx-auto' style={{ display: `${isButtonShow ? "block" : "none"}` }} onClick={handleClick}>
                        Show previous months
					</button>
				</div>
			</div>
		</Layout>
	);
}

export const query = graphql`
	query {
		contentfulPage(id: { eq: "886b7d5b-7bd1-5817-8879-d6c4c1b6e965" }) {
			...CurrentPageDetails
		}
		allContentfulEntry {
			edges {
				node {
					...AllEntryDetails
				}
			}
		}
	}
`;
